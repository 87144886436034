const { showErrorMessage, debounce } = require('../../functions')

function fillAddress(address, scope) {
  scope = scope || 'Address'
  if (address === 0) {
    $("[name^='" + scope + "']").val('')
  } else {
    if (address === undefined) {
      return false
    }
    for (const i in address) {
      const $el = $("[name='" + scope + '[' + i + "]']")
      if ($el.length) {
        $el.val(address[i])
      }
    }
  }
  $("[name='" + scope + "[stateId]']").trigger('change')
}

$(() => {
  $('.checkout form').on('submit', function () {
    if ($(this).valid()) {
      const submit = $(this).find('input:submit')
      if (submit.length) {
        submit.attr('disabled', 'disabled')
      }
    }
  })

  $(document).on('change', '#defaultAddress', function () {
    const id = parseInt($(this).val()),
      checkForSave = $('#address-issaved')
    if (id === 0) {
      checkForSave.parent().show()
      return fillAddress(id)
    }
    if (addresses[id] !== undefined) {
      checkForSave.removeAttr('checked')
      checkForSave.parent().hide()
      fillAddress(addresses[id])
    }
  })

  $(document).on('change', '#fromAddress', function () {
    const id = parseInt($(this).val()),
      checkForSave = $('#fromAddressIsSaved')
    if (id === 0) {
      checkForSave.parent().show()
      return fillAddress(id, 'fromAddress')
    }
    if (addresses[id] !== undefined) {
      checkForSave.removeAttr('checked')
      checkForSave.parent().hide()
      fillAddress(addresses[id], 'fromAddress')
    }
  })

  // Copy shipping address to the payment address form
  $(document).on('change', 'input[name=asShipping]', function () {
    if (address === undefined) {
      return
    }

    const shouldClear = !$(this).is(':checked')
    for (var i in address) {
      const el = $('[name=' + i + ']')
      if (el.length) {
        const value = i === 'stateId' ? states[address[i]] : address[i]

        el.val(shouldClear ? '' : value)
      }
    }

    $('#card-billing-address-state').trigger('change')
  })

  $(document).on('change', '.paymentSwitch', function () {
    const self = $(this)
    const id = self.attr('id')
    const submitButton = $('#card-field-submit-button')

    $('.payment-boxes').hide()

    $('#' + id + '_box').show()

    id === 'paypal' ? submitButton.hide() : submitButton.show()
  })

  $(document).on('click', '.shipping-types input:radio', function () {
    $('.shipping-types label').removeClass('selected')
    $(this).closest('label').addClass('selected')
    $('.estimate').hide()
    $('#estimate_' + $(this).val()).show()
  })

  const looksValidation = $('#looksValidation')
  if (looksValidation.length) {
    $('html, body').animate({ scrollTop: looksValidation.offset().top }, 1000)
  }
  $(document).on('click', '.valid-address label', function () {
    $('.valid-address div').removeClass('active')
    $(this).closest('div').addClass('active')
  })

  $(document).on('change', '#address-isdropship', function () {
    $('.drop-shipping').toggle()
    if (!$(this).is(':checked')) {
      $('.drop-shipping input, .drop-shipping select').val('').trigger('change')
    }
  })

  const savedCards = $('#savedCards')
  if (savedCards.length) {
    function toggleUserCards() {
      const cards = $('.toggle-cards')
      const userCard = $('.user-card')
      if (parseInt($(this).val()) === 0) {
        userCard.find('input').val('')
        return userCard.hide() & cards.show()
      }
      cards.find('input,select').val('').trigger('change')
      return userCard.show() & cards.hide()
    }

    toggleUserCards.call(savedCards)
    savedCards.on('change', toggleUserCards)
  }

  $(document).on('click', '#js-complete-order', function (e) {
    e.preventDefault()
    const button = e.target

    button.disabled = true
    button.innerText = 'Processing...'

    const csrf = {
      [$('meta[name="csrf-param"]').attr('content')]: $(
        'meta[name="csrf-token"]'
      ).attr('content'),
    }

    $.post(
      '/checkout/approve/',
      {
        ...csrf,
        subscribe: $('input[name=subscribe]').is(':checked') ? 1 : 0,
      },
      (res) => {
        if (res.error) {
          button.disabled = false
          button.innerText = 'Complete Order'

          return showErrorMessage(res.error)
        }

        window.location.assign('/checkout/receipt/?order=' + res.order)
      }
    ).fail((err) => {
      button.disabled = false
      button.innerText = 'Complete Order'

      showErrorMessage('Error processing order payment. Please try again.')
      console.error(err)
    })
  })

  function initPlacesAutocomplete(elements) {
    const { address1, city, state, zip, zip4 } = elements

    function applySelectedPlace(place) {
      const types = {
        street_number: address1,
        route: address1,
        locality: city,
        administrative_area_level_1: state,
        postal_code: zip,
        postal_code_suffix: zip4,
      }

      $.getJSON('/ajax/places/?id=' + place.place_id).done(function (data) {
        Object.values(elements).forEach((el) => el.val('').trigger('change'))

        if (data.status !== 'OK') {
          return
        }

        for (var component of data.result.address_components) {
          for (var type of component.types) {
            if (!types[type]) {
              continue
            }

            var input = $(types[type])
            if (type === 'administrative_area_level_1') {
              var option = input.find(
                'option:contains("' + component.short_name + '")'
              )
              if (option.length) {
                input.val(option.val()).trigger('change')
              }
              continue
            }
            input.val([input.val(), component.long_name].join(' ').trim())
          }
        }
      })
    }

    const hitsContainer = $('<div/>').addClass('places-container')

    address1.after(hitsContainer)

    address1.on(
      'keyup',
      debounce(function (e) {
        const value = e.target.value

        if (value.length < 3) {
          hitsContainer.hide()
          return
        }

        function showHits(data) {
          hitsContainer.empty()

          if (!data.predictions || !data.predictions.length) {
            return hitsContainer.hide()
          }

          data.predictions.forEach(function (prediction) {
            const button = $("<button type='button'/>")
              .text(prediction.description)
              .on('click', function () {
                applySelectedPlace(prediction)
                hitsContainer.hide()
              })

            hitsContainer.append(button)
          })

          hitsContainer.show()
        }

        $.getJSON('/ajax/places/?query=' + value).done(showHits)
      }, 200)
    )

    document.addEventListener('click', function (e) {
      if (
        !hitsContainer.is(e.target) &&
        hitsContainer.has(e.target).length === 0 &&
        !$(e.target).hasClass('places-container')
      ) {
        hitsContainer.hide()
      }
    })

  }

  const shippingAddress1 = $('#address-address1')
  if (shippingAddress1.length) {
    initPlacesAutocomplete({
      address1: shippingAddress1,
      address2: $('#address-address2'),
      city: $('#address-city'),
      state: $('#address-stateid'),
      zip: $('#address-zip'),
      zip4: $('#address-zip4'),
    })
  }

  const dropShipAddress1 = $('#fromAddressAddress1')
  if (dropShipAddress1.length) {
    initPlacesAutocomplete({
      address1: dropShipAddress1,
      address2: $('#fromAddressAddress2'),
      city: $('#fromAddressCity'),
      state: $('#fromAddressState'),
      zip: $('#fromAddressZip'),
    })
  }

})
